// COLORS
$red: #800000;
$darkred: #670a0a;
$darkshedred: #8f1f1f;
$darkestred: #75140c;
$dark-terracotta: #a9431e;
$violet: #59315f;
$darkviolet: #41273b;
$yellow: #f3d03e;
$green: #789d4a;
$lake: #007396;
$darklake: #002a3a;
$darkbluegrey: #002a3a;
$lightestgrey: #f8f8f8;
$lightergrey: #f3f3f3;
$lightgrey: #d9d9d9;
$mediumergrey: #b3b3b3;
$anothergrey: #737373;
$mediumgrey: #666666;
$grey: #4d4d4d;
$darkgrey: #404040;
$darkshedgrey: #a6a6a6;
$black: #000000;
$white: #ffffff;
$goldenrod: #eaaa00;
$forest: #275d38;
$terracotta: #de7c00;
$whisper: #e9e9e9;

$alignments: (
    left-top: left top,
    center-top: center top,
    right-top: right top,
    left-center: left center,
    center-center: center center,
    right-center: right center,
    left-bottom: left bottom,
    center-bottom: center bottom,
    right-bottom: right bottom,
);

$colors: (
    red: $red,
    darkred: $darkred,
    terracotta: $dark-terracotta,
    violet: $violet,
    darkviolet: $darkviolet,
    yellow: $yellow,
    lake: $lake,
    darklake: $darklake,
    darkbluegrey: $darkbluegrey,
    lightergrey: $lightergrey,
    lightgrey: $lightgrey,
    mediumgrey: $mediumgrey,
    grey: $grey,
    black: $black,
    green: $green,
    white: $white,
    goldenrod: $goldenrod,
    forest: $forest,
);

// FONTS

$sans-serif-light: "GothamLight", "GothamLightItalic", sans-serif;
$sans-serif-normal: "GothamBook", "GothamBookItalic", sans-serif;
$sans-serif-medium: "GothamMedium", "GothamMediumItalic", sans-serif;
$sans-serif-bold: "GothamBold", "GothamBoldItalic", sans-serif;

$serif: "adobe-garamond-pro", serif;
$fontAwesome: "Font Awesome 6 Pro", "Font Awesome 6 Brands";

$sans-light: 300;
$sans-normal: 400; // a.k.a. 'Gotham Book'
$sans-medium: 500;
$sans-bold: 700;

$serif-normal: 400; // a.k.a. 'Garamond Regular'
$serif-semibold: 600;
$serif-bold: 700;

// SCREEN SIZES
$mobile-smaller: 540px;
$mobile: 700px;
$mobile-larger: 900px;
$desktop-smaller: 1100px;

// CONTAINERS
$smallIpadProContainer: 1000px;
$innerContainer: 1200px;
$smallContainer: 1300px;
$smallWidthDesktopContainer: 1440px;
$largeContainer: 1560px;
//13 instances could be replaced with
$twoColMaxWith: 1488px;

//Shared Sizing
$contentBufferSize: 30px;
$headerGutter: 24px;
$desktopHeaderTopRow: 48px;
$desktopHeaderBottomRow: 80px;