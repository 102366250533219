@import "../../scss/variables";

*[class*='SectionWrap_container'] {
    + .container {
        margin-top: 0;
    }
}

.container {
    display: flex;
    flex-wrap: wrap;
    max-width: $largeContainer;
    margin: 32px auto;
    position: relative;
    padding: 0;

    &:before {
        background-color: $red;
        content: "";
        display: block;
        height: 12px;
        left: 0;
        max-width: 474px;
        position: absolute;
        top: 0;
        width: 50%;
        z-index: 1;
    }
}

.imageContainer {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    flex: 0 0 50%;
    max-width: 50%;
    min-height: 400px;
    max-height: 100%;
    position: relative;

    &[data-alignment="right"] {
        order: 1;
    }

    .tabDescription {
        background-color: $darkgrey;
        bottom: 0;
        opacity: 0.7;
        position: absolute;
        padding: 8px 10px;
        width: 100%;

        .description {
            color: $white;
            display: inline;
        }

        &:empty {
            display: none;
        }

        .selectedCta {
            display: inline-block;
            font-family: $sans-serif-medium;
            font-weight: $sans-medium;
            text-transform: uppercase;
            margin-left: 5px;

            &:hover {
                &,
                *,
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}

.tabContainer {
    background-color: $lightergrey;
    background-repeat: no-repeat;
    background-position: center right;
    display: flex;
    flex: 0 0 50%;
    flex-direction: column;
    max-width: 50%;
    padding: 48px 76px 16px 60px;
    position: relative;

    .title {
        color: $red;
        font-size: 36px;
        font-family: $serif;
        font-weight: $serif-normal;
        line-height: 48px;
        letter-spacing: normal;
        position: relative;
        text-transform: inherit;
    }

    .description {
        color: $grey;
        font-family: $sans-serif-normal;
        font-weight: $sans-normal;
        line-height: 28px;
        margin-top: 15px;
        position: relative;
    }

    .ctaLink {
        margin-top: 20px;
        position: relative;

        .cta {
            font-family: $sans-serif-medium;
            font-weight: $sans-medium;
            letter-spacing: 1.5px;
            text-transform: uppercase;
        }
    }

    .ctaLinkContainer {
        margin-top: auto;
        padding-top: 52px;

        .tabLink {
            margin-top: 0;
            margin-bottom: 20px;

            &::before {
                content: none;
            }

            .tabCta {
                font-size: 16px;
                font-family: $sans-serif-medium;
                font-weight: $sans-medium;
                letter-spacing: normal;
                line-height: 18px;

                [class*="linkText"] {
                    border: none;
                }

                &:hover {
                    font-family: $sans-serif-bold;
                    font-weight: $sans-bold;
                }

                &,
                &:hover {
                    &,
                    [class*="linkText"] {
                        transition: none;
                    }
                }
            }
        }
    }   

    &[data-alignment="top-left"] {
        background-position: left top;
    }

    &[data-alignment="top-right"] {
        background-position: right top;
    }    

    &[data-alignment="center-left"] {
        background-position: center left;
    }

    &[data-alignment="bottom-left"] {
        background-position: left bottom;
    }

    &[data-alignment="center-right"] {
        background-position: center right;
    }

    &[data-alignment="middle-right"] {
        background-position: center right;
    }

    &[data-alignment="bottom-right"] {
        background-position: right bottom;
    }
    
    &[data-alignment="center"] {
        background-position: center center;
    }

    &[data-alignment="scale"] {
        background-position: center center;
        background-size: contain;
    }
}

@media screen and (max-width: $mobile-larger) {
    .container {
        margin: 30px auto;
    }

    .imageContainer {
        flex: 0 0 100%;
        min-height: 311px;
        max-width: 100%;

        &[data-alignment="right"] {
            order: 1;
        }
    }

    .tabContainer {
        background-position: 50% 50%;
        flex: 0 0 100%;
        max-width: 100%;
        order: 2;

        .ctaLinkContainer {
            padding-top: 48px;
        }
    }
}

@media screen and (max-width: $mobile) {
    .tabContainer {
        padding: 32px 24px 8px;
    }
}
